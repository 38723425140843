import React, { useEffect } from "react";
import OpensourceOfferings from "../Offerings/openSource";
// import Testimonials from '../Testimonials'
import ServiceOfferings from "../Offerings/service";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";
import AOS from "aos";
import "aos/dist/aos.css";

const HomePageTemplate = ({ open_source, testimonials, service }) => {
  useEffect(() => {
    AOS.init({
      duration: 950,
    });
    AOS.refresh();
  });
  return (
    <>
      <h2 className="title has-text-weight-semibold is-size-2">
        <div className="level">
          <div className="level-left">
            {open_source.heading.map((sentence) => (
              <div key={sentence} className="level-item sentence">
                {sentence}
              </div>
            ))}
          </div>
        </div>
      </h2>
      <div className="mb-6">
        <ReactMarkdown source={open_source.description} plugins={[breaks]} />
      </div>
      <OpensourceOfferings offerings={open_source.offerings} />
      {/* <h2 className='has-text-weight-semibold is-size-2'>Testimonials</h2>
      <Testimonials testimonials={testimonials} /> */}
      <h2 className="title has-text-weight-semibold is-size-2" id="service">
        {service.heading}
      </h2>
      <div className="mb-6">
        <ReactMarkdown source={service.description} plugins={[breaks]} />
      </div>
      <ServiceOfferings offerings={service.offerings} />
    </>
  );
};

HomePageTemplate.propTypes = {
  open_source: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    offerings: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        blurbs: PropTypes.arrayOf(
          PropTypes.shape({
            image: PropTypes.string,
            badges: PropTypes.arrayOf(PropTypes.string),
            text: PropTypes.string,
          })
        ),
      })
    ),
  }),
  testimonials: PropTypes.array,
  service: PropTypes.object,
};

export default HomePageTemplate;
