import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import BulmaModalFx from "../Bulma/Modal/Fx";
import breaks from "remark-breaks";

const ServiceOfferings = ({ offerings }) => (
  <div className="blurbs columns is-multiline">
    {offerings.map(({ title, summary, detail }) => (
      <div
        key={title}
        className="column is-6"
        style={{ borderRadius: "5px" }}
        data-aos="fade-up"
      >
        <div className="box service">
          <div className="box category">
            <h3 className="subtitle is-4 has-text-centered is-italic mb-0 category">
              {title}
            </h3>
          </div>
          <section className="section">
            <ReactMarkdown source={summary} unwrapDisallowed />
            <div>
              <BulmaModalFx buttonTitle="Expand Detail">
                <ReactMarkdown source={detail} plugins={[breaks]} />
              </BulmaModalFx>
            </div>
          </section>
        </div>
      </div>
    ))}
  </div>
);

ServiceOfferings.propTypes = {
  offerings: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      summary: PropTypes.string,
      detail: PropTypes.string,
    })
  ),
};

export default ServiceOfferings;
