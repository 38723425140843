import React from "react";
import Helmet from "react-helmet";
import Hero from "../components/Hero";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import HomePageTemplate from "../components/HomePageTemplate";
import Layout from "../components/Layout";
import SectionGradient from "../components/SectionGradient";

const HomePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { childImageSharp } = data.file;

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.meta_title}</title>
        <meta name="description" content={frontmatter.meta_description} />
      </Helmet>
      <Hero
        title={frontmatter.title}
        background_image={childImageSharp.fluid}
      />
      <SectionGradient>
        <HomePageTemplate
          open_source={frontmatter.open_source[0]}
          testimonials={frontmatter.testimonials}
          service={frontmatter.service[0]}
        />
      </SectionGradient>
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default HomePage;

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        open_source {
          heading
          description
          offerings {
            category
            blurbs {
              title
              image
              badges
              text
            }
          }
        }
        testimonials {
          author
          quote
        }
        service {
          heading
          description
          offerings {
            title
            summary
            detail
          }
        }
      }
    }
    file(relativePath: { eq: "marina-loucaides-VmziYgps3T.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
