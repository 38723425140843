import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const Badges = ({ sources }) => (
  <div>
    {sources.map((badge) => (
      <>
        <ReactMarkdown
          source={badge}
          disallowedTypes={["paragraph"]}
          unwrapDisallowed
        />
        {"\n"}
      </>
    ))}
  </div>
);

Badges.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string),
};

export default Badges;
