import React from "react";
import Badges from "../Badges";
import PropTypes from "prop-types";
import { withPrefix } from "gatsby";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";

const OpensourceOfferings = ({ offerings }) => (
  <>
    {offerings.map(({ category, blurbs }) => (
      <>
        <div className="box category">
          <h3 className="subtitle is-2 has-text-centered is-italic mb-0 category">
            {category}
          </h3>
        </div>
        <div className="blurbs columns is-multiline">
          {blurbs.map((blurb) => (
            <div
              key={blurb.image}
              className="column is-6"
              style={{ borderRadius: "5px" }}
              data-aos="fade-up"
            >
              <div className="box open-source">
                <section className="section">
                  <h4 className="subtitle is-3">
                    <ReactMarkdown
                      source={blurb.title}
                      disallowedTypes={["paragraph"]}
                      unwrapDisallowed
                    />
                  </h4>
                  <p className="has-text-centered">
                    <img alt="" src={withPrefix(blurb.image)} />
                  </p>
                  <Badges sources={blurb.badges} />
                  <ReactMarkdown source={blurb.text} plugins={[breaks]} />
                </section>
              </div>
            </div>
          ))}
        </div>
      </>
    ))}
  </>
);

OpensourceOfferings.propTypes = {
  offerings: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      blurbs: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
          badges: PropTypes.arrayOf(PropTypes.string),
          text: PropTypes.string,
        })
      ),
    })
  ),
};

export default OpensourceOfferings;
